import { FlowService } from '@coaf-library/flow/flow';
import { Router } from 'vue-router';
import { computed, ComputedRef, Ref } from 'vue';
import { DevFlow, Next, NodePage, Value } from '@coaf-library/IO/db';
import { ResponseData, SignalR } from '@coaf-library/IO/signalRWrapper';
import { Store } from 'vuex';
import { State } from '@/coaf/store';
import sleep from '@coaf-library/helpers/sleep';

interface Progress {
  progress: number;
  index: number;
  pagesLength: number;
}

export interface WizardFlow {
  getActivePage(nodeID: string): NodePage | null | undefined;
  getFirstPage(submissionID: string, pageID: string | string[] | undefined): NodePage | null | undefined;
  submitValue(ID: string, values: Value[]): Promise<Next>;
  removeLatestValue(ID: string): Promise<Value[][]>;
  getNodesWithValuesComputed(activePage: ComputedRef<NodePage>): ComputedRef<NodePage[] | null> | undefined;
  computedNextPage(
    activePage: ComputedRef<NodePage | undefined | null>,
    currentValue: Ref<Value[]>
  ): ComputedRef<Node | undefined | null>;
  computedPrevPage(activePage: ComputedRef<NodePage | null | undefined>): ComputedRef<NodePage | null | undefined>;
  computedActivePage(routeParams: { [x: string]: string | string[] }, pageID: Ref<string>): ComputedRef<NodePage>;
  getProgress(page: ComputedRef<NodePage | undefined>): ComputedRef<number | Progress>;
  resetFlow(): Promise<ResponseData>;
  isAllowedToRedirect(): Promise<boolean>;
  devFlow: DevFlow;
}
import { flowRepository } from '@coaf-library/IO/flow-web-repository';

export const wizardFlowFactory = (
  flowService: ComputedRef<FlowService>,
  router: Router,
  webSocket: SignalR,
  store: Store<State>
): WizardFlow => {
  function getActivePage(nodeID: string) {
    return flowService.value.getNode(nodeID);
  }
  function getFirstPage(submissionID: string, pageID: string | string[] | undefined) {
    if (pageID !== undefined) {
      return null;
    }
    const page = flowService.value.getFirstNode();
    router.push(`${submissionID}/${page?.nodeId ?? ''}`);
    return page;
  }
  async function submitValue(ID: string, values: Value[]): Promise<Next> {
    const node = flowService.value.getNode(ID);

    store.dispatch('flowGraph/postValue', { ID, values });
    const backendNext: Next = await flowRepository.update('flow/node/data', {
      appName: flowService.value.appName,
      flowName: flowService.value.flowName,
      nodeId: ID,
      values,
      context: node?.context,
    });
    // TODO: Fix race condition better
    await sleep(100);

    if (backendNext?.hasNewFlowId) {
      if (ID.toLowerCase() !== 'overview') {
        await webSocket.post('FinishFlow', {
          appName: flowService.value.appName,
          flowName: flowService.value.flowName,
        });
      }
      // TODO: Fix race condition better
      await sleep(100);
      // await store.dispatch('flowGraph/createGraph', {
      //   appID: flowService.value.appName,
      //   flowID: flowService.value.flowName,
      //   router,
      // });
      return backendNext;
    }
    const nextPage = backendNext.nodeId ?? backendNext.nextFlow;
    store.dispatch('flowGraph/transitionState', { nodeID: nextPage });
    return backendNext;
  }

  function computedNextPage(activePage: ComputedRef<NodePage | undefined | null>, currentValue: Ref<Value[]>) {
    return computed(() => {
      const activeValues = currentValue.value?.filter((val: Value) => {
        return val !== undefined;
      });
      if (activePage.value === undefined || activePage.value === null) {
        return null;
      }

      if (flowService.value.areAllInputComponentsValid(activePage.value?.components, activeValues) == false) {
        return null;
      }
      // Get the branch in the graph that corresponds to the value chosen
      const next = flowService.value.getNextNode(activePage.value.nodeId, activeValues);
      return next;
    });
  }
  function computedPrevPage(activePage: ComputedRef<NodePage | undefined>) {
    return computed(() => {
      if (activePage.value === undefined) {
        return null;
      }
      return flowService.value.getPreviousNode(activePage.value.nodeId);
    });
  }
  function computedActivePage(routeParams: { [x: string]: string | string[] }, pageID: ComputedRef<string>) {
    return computed(() => {
      // TODO: Refactor routeParams hack to check for missing pageID in params.
      return getFirstPage(routeParams.flowID as string, routeParams.pageID) ?? getActivePage(pageID.value);
    });
  }
  function getProgress(page: ComputedRef<NodePage | undefined>) {
    if (page === undefined) {
      throw new Error('No ID provided for progress');
    }
    const pagesLength = flowService.value.getFlowLength();
    return computed(() => {
      if (page.value === undefined) {
        return 0;
      }
      const index = flowService.value.getNodeIndex(page.value.nodeId) + 1;
      return { progress: index / pagesLength, index, pagesLength };
    });
  }

  function getNodesWithValuesComputed(activePage: ComputedRef<NodePage>): ComputedRef<NodePage[] | null> | undefined {
    return computed(() => {
      const previousNode = flowService.value.getPreviousNode(activePage.value.nodeId);
      if (previousNode === null) {
        return null;
      }
      const nodes = flowService.value.getAllPreviousNodes(previousNode.nodeId).reverse();
      return nodes;
    });
  }

  function removeLatestValue(ID: string): Promise<Value[][]> {
    return store.dispatch('flowGraph/removeValuesFromNode', { ID });
  }

  async function resetFlow(): Promise<ResponseData> {
    webSocket.post('ResetFlow', {
      appName: flowService.value.appName,
      flowName: flowService.value.flowName,
    });
    const response = (await new Promise((resolve, reject) => {
      const subscription = webSocket.subscribe('FlowReset');
      subscription((response) => {
        webSocket.unsubscribe('FlowReset');
        if (response.success) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    })) as ResponseData;
    return response;
  }

  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    computedNextPage,
    computedActivePage,
    computedPrevPage,
    submitValue,
    getNodesWithValuesComputed,
    removeLatestValue,
    getProgress,
    resetFlow,
    devFlow: flowService.value?.listFlow(),
  };
};
